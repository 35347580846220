<template>
  <q-page-container class="bg-white">

    <q-page class="flex flex-center justify-center">

      <div class="col col-lg-8 col-sm-9 col-xs-10 q-pa-md flex column items-center" style="min-width: 370px">
        <div class="text-h4 text-primary q-mb-md text-center">Abbiamo mandato una email al tuo indirizzo di posta</div>
        <div class="text-h5 text-primary q-mb-md text-center">Controlla la tua casella e clicca sul link che trovi nell'email</div>
        <q-btn no-caps label="Ritorna alla home" type="reset" to="/"/>
      </div>
    </q-page>
  </q-page-container>
</template>

<style>
</style>

<script>

export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
